import React from 'react'
import { graphql } from 'gatsby'

import PageFactory from '@/components/PageFactory'

class FlowerEyewearSecondaryPage extends React.Component<any, any> {
  render() {
    const { page, header, footer, location } = this.props.data
    console.log(this.props.data)
    return <PageFactory page={page} footer={footer} location={location} header={header} />
  }
}

export default FlowerEyewearSecondaryPage

export const query = graphql`
  query FlowerEyewearSecondaryPage {
    page: datoCmsFlowerEyewearSecondaryPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      sections {
        __typename
        ...allSections
      }
    }

    header: datoCmsNavSection {
      ...NavSection
    }

    footer: datoCmsFooter {
      ...Footer
    }
  }
`
